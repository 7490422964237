import React, { useCallback, useState } from "react";
import { message } from "antd";
import axios from "axios";

const ContactFormTalent = () => {
	const [formData, setFormData] = useState({
		name: "",
		email: "",
		phone: "",
		subject: "",
		description: "",
	});

	const handleChange = useCallback(
		(e) => {
			const { name, value } = e.target;
			setFormData({
				...formData,
				[name]: value,
			});
		},
		[formData]
	);

	const resetForm = useCallback(() => {
		setFormData({
			name: "",
			email: "",
			phone: "",
			subject: "",
			description: "",
		});
	}, []);

	const handleSubmit = useCallback(
		(e) => {
			e.preventDefault();
			if (formData.email && formData.name) {
				(async () => {
					try {
						await axios.get(
							`https://q60eodk803.execute-api.eu-west-1.amazonaws.com/eurosom-send-info-email?name=${formData.name}&email=${formData.email}&phone=${formData.phone}&subject=${formData.subject}&description=${formData.description}`
						);
					} catch (error) {
						console.log(error);
					}
				})();
				message.success("Form submittion succeed, Thank you!");
				resetForm();
			} else {
				message.info("Please fill the inputs.");
			}
		},
		[resetForm, formData]
	);

	return (
		<div
			className="container-fluid mt-5 position-relative"
			style={{
				zIndex: 2,
				backgroundColor: "#e8f4ff",
				padding: "20px",
			}}
		>
			<h2 className="text-center" style={{ color: "#007bff" }}>
				CONTACT US TODAY
			</h2>
			<div className="container">
				<div className=" row">
					<div className="col-md-6">
						<form onSubmit={handleSubmit}>
							<div className="row">
								<div className="col-12 col-md-6 mb-3">
									<input type="text" className="p-3 form-control" placeholder="Your Name" name="name" value={formData.name} onChange={handleChange} />
								</div>
								<div className="col-12 col-md-6 mb-3">
									<input type="email" className="p-3 form-control" placeholder="Your Email" name="email" value={formData.email} onChange={handleChange} />
								</div>
							</div>
							<div className="row">
								<div className="col-12 col-md-6 mb-3">
									<input type="tel" className="p-3 form-control" placeholder="Business Phone Number" name="phone" value={formData.phone} onChange={handleChange} />
								</div>
								<div className="col-12 col-md-6 mb-3">
									<select className="p-3 form-control" name="subject" value={formData.subject} onChange={handleChange}>
										<option value="">Select a Subject</option>
										<option value="Service Inquiry">Service Inquiry</option>
										<option value="Support">Support</option>
										<option value="Other">Other</option>
									</select>
								</div>
							</div>
							<div className="mb-3">
								<textarea
									className="form-control"
									rows="3"
									placeholder="Describe your requirement"
									name="description"
									value={formData.description}
									onChange={handleChange}
								></textarea>
							</div>
							<button type="submit" className="btn btn-primary w-40 rounded-pill">
								Contact Us
							</button>
						</form>
					</div>
					<div className="col-md-6">
						<div className="d-flex align-items-center mb-3">
							<i className="bi bi-envelope-fill me-2" style={{ color: "#007bff", fontSize: "20px" }}></i>
							<span>Email: info@eurosom.com</span>
						</div>
						<div className="d-flex align-items-center mb-3">
							<i className="bi bi-telephone-fill me-2" style={{ color: "#007bff", fontSize: "20px" }}></i>
							<span>Contact Number: +44 7564 80 8380, +44 7947 93 6544</span>
						</div>
						<div className="d-flex align-items-center mb-3">
							<i className="bi bi-geo-alt-fill me-2" style={{ color: "#007bff", fontSize: "20px" }}></i>
							<span>86-90 Paul Street, London, England, United Kingdom, EC2A 4NE.</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactFormTalent;
