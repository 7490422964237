import React, { useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
// import cardData from "../Services/cardData";
import mainBannerImg from "../../images/banner-images/digital-workplace/digital-workplace_Img1.svg";
import digitalWorkplaceImg1 from "../../images/banner-images/app-developer/cloud-engineer/cloud-coding.svg";
import digitalWorkplaceImg2 from "../../images/banner-images/app-developer/dev-ops/engineer.svg";
import digitalWorkplaceImg3 from "../../images/banner-images/cloud-services/cloud-services_Img1.svg";
import digitalWorkplaceImg4 from "../../images/banner-images/erp-odoo-saas/erp-odoo-saas_Img2.svg";
import digitalWorkplaceImg5 from "../../images/banner-images/digital-workplace/digital-workplace_Img5.svg";
import digitalWorkplaceImg6 from "../../images/banner-images/big-data-analytics/big-data-analyt_Img1.svg";
import digitalWorkplaceImg7 from "../../images/banner-images/app-developer/agile-manager/engineer.svg";

import ArtificialIntelligence from "../../images/banner-images/ai-ml-iot-applications/ai-ml-applications_Img1.svg";
import CloudComputing from "../../images/banner-images/cloud-services/cloud-services_Img1.svg";
import BigDataAnalytics from "../../images/banner-images/big-data-analytics/big-data-analyt_Img1.svg";
import InternetofThings from "../../images/banner-images/network-surveillance/network-surveillance_Img3.svg";
import CyberSecurity from "../../images/banner-images/cyber-security/cyber-security_Img1.svg";
import ErpOdooDeveloper from "../../images/banner-images/app-developer/erp-odoo/human-resource.svg";
import WebMobDeveloper from "../../images/banner-images/app-developer/developer/developer.svg";
import DroneTechExpert from "../../images/banner-images/cyber-security/cyber-security_Img6.svg";
import BusinessIntelligence from "../../images/banner-images/digital-workplace/digital-workplace_Img4.svg";
import HireExpert from "../../images/home/hire.svg";
import MobileDevelopment from "../../images/home/application.svg";
import DevOpsEngineer from "../../images/banner-images/app-developer/dev-ops/engineer.svg";
import ContactFormTalent from "./ContactFormTalent";
import erp from "../../images/banner-images/find-talent/erp.svg";

const FindTalent = () => {
	const navigate = useNavigate();
	const cardExpertise = [
		{
			id: 1,
			img: CloudComputing,
			title: "Cloud Solutions Architect",
		},
		{
			id: 2,
			img: ArtificialIntelligence,
			title: "AI/ML Engineer",
		},
		{
			id: 3,
			img: CyberSecurity,
			title: "Cybersecurity Specialist",
		},
		{
			id: 4,
			img: InternetofThings,
			title: "Data Scientist",
		},
		{
			id: 5,
			img: BigDataAnalytics,
			title: "Data Analyst",
		},
		{
			id: 6,
			img: DevOpsEngineer,
			title: "DevOps Engineer",
		},
		{
			id: 7,
			img: digitalWorkplaceImg6,
			title: "AI Developer",
		},
		{
			id: 8,
			img: BusinessIntelligence,
			title: "Business Intelligence",
		},
		{
			id: 9,
			img: ErpOdooDeveloper,
			title: "ERP Odoo Developer",
		},
		{
			id: 10,
			img: WebMobDeveloper,
			title: "Software Engineer (Mobile & Web)",
		},
		{
			id: 11,
			img: HireExpert,
			title: "Digital Marketing Specialist",
		},
		{
			id: 12,
			img: DroneTechExpert,
			title: "Drone Technology Specialist",
		},
		{
			id: 13,
			img: MobileDevelopment,
			title: "Advanced Technology Consultant",
		},
	];
	const cardData = [
		{
			id: 1,
			imgSrc: digitalWorkplaceImg1,
			title: "Cloud Engineers",
			description: "Experts in building, managing, and maintaining robust cloud infrastructures.",
		},
		{
			id: 2,
			imgSrc: digitalWorkplaceImg2,
			title: "DevOps Engineers",
			description: "Professionals who streamline development and operational processes for increased agility",
		},
		{
			id: 3,
			imgSrc: digitalWorkplaceImg3,
			title: "Cloud Architects",
			description: "Visionaries who design scalable, efficient, and secure cloud solutions.",
		},
		{
			id: 4,
			imgSrc: erp,
			title: "ERP Consultants",
			description: "Specialists who integrate and optimize enterprise resource planning systems.",
		},
		{
			id: 5,
			imgSrc: digitalWorkplaceImg5,
			title: "Prompt Engineer Consultants",
			description: "Experts in crafting and optimizing prompts for AI solutions.",
		},
		{
			id: 6,
			imgSrc: digitalWorkplaceImg6,
			title: "Data Analysts",
			description: "Professionals who deliver actionable insights to drive data-driven decision-making.",
		},
		{
			id: 7,
			imgSrc: digitalWorkplaceImg7,
			title: "Platform Decision Makers",
			description: "Leaders providing board-level insights to drive strategic cloud transformations.",
		},
	];
	// Create a ref for each detailed card
	const cardRefs = useRef([]);
	const handleContactEurosomOnClick = () => {
		navigate("/contact-us");
	};
	// Function to handle scroll to the detailed card
	const scrollToCard = (id) => {
		const cardElement = cardRefs.current[id];
		if (cardElement) {
			cardElement.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	useEffect(() => {
		const handleScroll = () => {
			cardRefs.current.forEach((card) => {
				if (!card) return;
				const rect = card.getBoundingClientRect();
				if (rect.top < window.innerHeight - 100) {
					card.classList.add("card-visible");
					card.classList.remove("card-hidden");
				} else {
					card.classList.add("card-hidden");
					card.classList.remove("card-visible");
				}
			});
		};

		window.addEventListener("scroll", handleScroll);
		handleScroll(); // Initialize on component mount
		return () => window.removeEventListener("scroll", handleScroll);
	}, []);

	return (
		<>
			<div>
				{/* SEO Configuration */}
				<Helmet>
					<title>Your Advanced Technology Talent Partner.</title>
					<meta
						name="description"
						content="At Innovodar, we specialize in connecting exceptional professionals in advanced technologies with organizations that demand world-class expertise. Based in London, United Kingdom, we are the resourcing arm of Eurosom, delivering tailored staffing solutions to help businesses thrive in today's technology-driven world."
					/>
					<meta
						name="keywords"
						content="At Innovodar, we specialize in connecting exceptional professionals in advanced technologies with organizations that demand world-class expertise. Based in London, United Kingdom, we are the resourcing arm of Eurosom, delivering tailored staffing solutions to help businesses thrive in today's technology-driven world."
					/>
					<link rel="canonical" href="https://Eurosom.com/find-talent" />
				</Helmet>

				<div className="container-fluid pt-5 mt-5 my-5 p-lg-5">
					<div className="row d-flex justify-content-around align-items-center ">
						<div className="col-md-6 col-12">
							<h1 className="text-primary fs-1 fw-bolder">Your Advanced Technology Talent Partner.</h1>
							<p className="fs-4 fw-semibold text-dark-emphasis">
								At Innovodar, we specialize in connecting exceptional professionals in advanced technologies with organizations that demand world-class expertise. Based in London,
								United Kingdom, we are the resourcing arm of Eurosom, delivering tailored staffing solutions to help businesses thrive in today's technology-driven world.
							</p>
							<button className="btn btn-primary rounded-pill mt-3" onClick={handleContactEurosomOnClick}>
								Contact Eurosom
							</button>
						</div>
						<div className="col-md-4 col-12 cont-image ">
							<img src={mainBannerImg} alt="working smarter, flexibility" className="img-fluid " />
						</div>
					</div>
				</div>

				{/* WHY CHOOSE INNOVODAR? */}
				<div className="container-fluid">
					<div className="container">
						<h3 className="text-center fw-bold">WHY CHOOSE INNOVODAR?</h3>
						<p className="text-center fs-4 mb-5">
							Navigating the complexities of advanced technologies requires more than just technical expertise; it demands vision, innovation, and top-tier talent. That's where
							Innovodar comes in. We bridge the gap between skilled professionals and businesses, ensuring every placement is a perfect match for your needs.
						</p>
					</div>
				</div>

				{/* OUR EXPERTISE */}
				<div className="container-fluid">
					<div className="container">
						<h3 className="text-center fw-bold">OUR EXPERTISE</h3>
						<h1 className="text-center text-primary fw-bold fs-1">We connect you with professionals specializing in</h1>
					</div>

					<div className="row px-lg-5 d-flex justify-content-center my-5">
						{cardExpertise.map((item) => (
							<div key={item.id} className="card col-12 col-sm-2 col-md-3 col-lg-3" style={{ width: "20rem", height: "16rem" }}>
								<img className="mt-3" src={item.img} alt={item.title} style={{ maxHeight: "100px", maxWidth: "100%" }} />
								<div className="card-body">
									<h5 className="card-title fw-bolder text-hover text-center mt-3">{item.title}</h5>
								</div>
							</div>
						))}
					</div>
					<div className="container">
						<p className="text-center fs-4 mb-5">
							Whether you're scaling your infrastructure, optimizing your cloud architecture, or leading transformative DevOps initiatives, Innovodar has access to the best talent
							network in the industry.
						</p>
					</div>
				</div>

				{/* Our Solution */}
				<div className="container-fluid">
					<div className="container">
						<h3 className="text-center fw-bold">OUR SPECIALTIES</h3>
					</div>

					<div className="row px-lg-5 d-flex justify-content-center my-5">
						{cardData.map((item) => (
							<div key={item.id} className="card col-12 col-sm-2 col-md-3 col-lg-3" style={{ width: "20rem", height: "16rem" }} onClick={() => scrollToCard(item.id)}>
								<img className="mt-3" src={item.imgSrc} alt={item.title} style={{ maxHeight: "100px", maxWidth: "100%" }} />
								<div className="card-body">
									<h5 className="card-title fw-bolder text-hover text-center mt-3">{item.title}</h5>
								</div>
							</div>
						))}
					</div>
				</div>

				{/* Detailed Solutions Section */}
				<div className="container">
					{cardData.map((item) => (
						<div
							key={item.id}
							className="card mb-3 border border-2 shadow-none rounded-0 p-0 card-hidden"
							ref={(el) => (cardRefs.current[item.id] = el)} // Set ref for each card
						>
							<div className="row g-0 my-3">
								{/* Image Section */}
								<div
									className="col-md-4 d-flex justify-content-center align-items-center bg-light"
									style={{
										maxWidth: "100%",
										// height: "240px",
										backgroundColor: "#f7f0ed",
									}}
								>
									<img src={item.imgSrc} className="img-fluid" alt={item.title} style={{ maxWidth: "30%", height: "auto" }} />
								</div>

								{/* Text Section */}
								<div className="col-md-8 col-12">
									<div className="card-body-b px-2 mt-4">
										<h5 className="card-title fw-bolder fs-4 text-primary">{item.title}</h5>
										<p className="card-text text-dark-emphasis fw-semibold fs-5">{item.description}</p>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>

				<div className="container-fluid my-5">
					<div className="container">
						<h3 className="text-center fw-bold">FLEXIBLE RESOURCING SOLUTIONS.</h3>
						<p className="text-center fs-4 mb-5">We recognize that every business is unique, which is why we offer flexible staffing solutions tailored to your requirements:</p>
						<div className="row px-lg-5 d-flex justify-content-center my-5">
							<div className="card col-12 col-sm-2 col-md-3 col-lg-3" style={{ width: "20rem", height: "16rem" }}>
								<div className="card-body">
									<h5 className="card-title fw-bolder text-hover text-center mt-3">Permanent Placements</h5>
									<p className="fs-5 text-center">For long-term roles that demand commitment and consistency.</p>
								</div>
							</div>
							<div className="card col-12 col-sm-2 col-md-3 col-lg-3" style={{ width: "20rem", height: "16rem" }}>
								<div className="card-body">
									<h5 className="card-title fw-bolder text-hover text-center mt-3">Contract Staffing</h5>
									<p className="fs-5 text-center">For project-specific needs or when you require quick access to expertise.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid my-5">
					<div className="container">
						<h3 className="text-center fw-bold">INNOVATING THE FUTURE OF ADVANCED TECHNOLOGIES.</h3>
						<p className="text-center fs-4 mb-5">
							At Innovodar, we go beyond recruitment by offering comprehensive first and <strong>second screening interviews</strong>, along with <strong>hands-on tests</strong>.
							This ensures that your hiring manager only needs to conduct the final decision-making interview and coordinate with HR for further discussions.
						</p>
						<p className="text-center fs-4 mb-5">
							We are committed to building long-term partnerships, understanding your business goals, and delivering talent solutions that empower your organization to innovate and
							succeed in the competitive tech landscape.
						</p>
					</div>
				</div>

				<div className="container-fluid my-5">
					<div className="container">
						<h3 className="text-center fw-bold">LET'S BUILD TOGETHER.</h3>
						<p className="text-center fs-4 mb-5">
							Looking to hire experts in advanced technologies? Or are you a professional ready to elevate your career? Innovodar is here to help. <strong>Contact us today</strong>{" "}
							to transform your resourcing needs into a seamless and rewarding experience.
						</p>
						<ContactFormTalent />
					</div>
				</div>
			</div>
		</>
	);
};

export default FindTalent;
